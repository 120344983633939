(function ($, emailjs, window, document, undefined) {

    'use strict';

    // $.leadit_url = {
    //   live: "https://app.leadit.quebec/customers/prospects.json",
    //   staging: "https://app.staging.leadit.quebec/customers/prospects.json",
    //   local: "http://localhost:3002/customers/prospects.json"
    // };

    // $.form_leadit = {
    //   valid: 'false',
    //   target: '',
    //   senEmail: ''
    // };

    // close menu on navigation click

    // POPUP
    // function PopUp(hideOrshow) {
    //     if (hideOrshow == 'hide') document.getElementById('ac-wrapper').style.display = "none";
    //     else document.getElementById('ac-wrapper').removeAttribute('style');
    // }
    // window.onload = function () {
    //     setTimeout(function () {
    //         PopUp('show');
    //     }, 200);
    // }

    var toggleMenu = (function ($) {
        jQuery('.click-mobile').click(function () {
            jQuery('#js-closeMenu').click();
        });
    });

    // Fix le header
    var fixHeader = function () {
        var $header = $('.header');
        $(window).scroll(function () {
            if ($(window).scrollTop() > 10 && !$header.hasClass('fixed')) {
                $header.addClass('fixed');
            } else if ($(window).scrollTop() < 11 && $header.hasClass('fixed')) {
                //$header.removeClass('fixed');
            }
        })
    };

    $(function() {
        var rotation = 0,
            scrollLoc = $(document).scrollTop();
        $(window).scroll(function() {
            var newLoc = $(document).scrollTop();
            var diff = scrollLoc - newLoc;
            rotation += diff, scrollLoc = newLoc;
            var rotationStr = 'rotate(' + rotation + 'deg)';
            $('.gear').css({
                '-webkit-transform': rotationStr,
                '-moz-transform': rotationStr,
                'transform': rotationStr
            });
        });
    })

    $(function() {
        var rotation = 0,
            scrollLoc = $(document).scrollTop();
        $(window).scroll(function() {
            var newLoc = $(document).scrollTop();
            var diff = scrollLoc - newLoc;
            rotation -= diff, scrollLoc = newLoc;
            var rotationStr = 'rotate(' + rotation + 'deg)';
            $('.gear2').css({
                '-webkit-transform': rotationStr,
                '-moz-transform': rotationStr,
                'transform': rotationStr
            });
        });
    })

    $(window).scroll(function() {
        var scrollDistance = $(window).scrollTop();

        // Assign active class to nav links while scolling
        $('#history').each(function(i) {
            console.log ($(this).position().top);
            if (Math.round($(this).position().top) <= scrollDistance) {
                $('li.addTo a.active').removeClass('active');
                $('li.addTo a').eq(i).addClass('active');
            } else {
                $('li.addTo a.active').removeClass('active');
            }
        });
    }).scroll();

    //FORM TECHNICAL*************************************************************
    var formCalltoAction = (function ($) {
        var $form = $('form#technical_form');

        var showFormError = function () {
            $form.find('.form-error')
                .html('Veuillez vérifier vos réponses, certains champs sont vides ou non valides').show();
        };

        var hideFormError = function () {
            $form.find('.form-error').html('').hide();
        };

        var isPhone = function (phone) {
            var regex = /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/;
            return regex.test(phone);
        };

        // $('#prospect_fullname').on('change', function () {
        //   $('#prospect_name').val($(this).val());
        // });
        //
        // $('#prospect_mail').on('change', function () {
        //   $('#prospect_email').val($(this).val());
        // });
        //
        // $('#prospect_phone_num').on('change', function () {
        //   var phone = $(this).val();
        //   var phonestripped = phone.replace(' ', '').replace('-', '').replace('(', '').replace(')', '').replace('-', '');
        //
        //   $('#prospect_phone_stripped').val(phonestripped);
        // });

        // $('form#info_form .form-checkbox-list label').on('click', function(){
        //   var prospect_name = $('#prospect_contract_name').val();
        //   prospect_name += " - " + $(this).html();
        //   $('#prospect_contract_name').val(prospect_name);
        // });

        // validate one field given its value and data type [email or phone]
        var validateField = function (v, dt) {
            var r = {
                'value': true,
                'message': ''
            };
            if (v === '') {
                r.value = false;
            }
            if (dt === 'email' && !isEmail(v)) {
                r.value = false;
            } else if (dt === 'phone' && !isPhone(v)) {
                r.value = false;
            }
            return r;
        };

        var validatePage = function ($page) {
            var $inputGroup = $page.find('.form-group');
            var valid = true;
            $inputGroup.each(function (i, e) {
                var $e = $(e);
                // Si checkbox ou radiobutton
                if ($e.hasClass('form-checkbox-list')) {
                    if ($e.find('input:radio:checked').length === 0) {
                        valid = false;
                    }
                } else {
                    $e.find('input, select').each(function (index, element) {
                        var $input = $(element);
                        var validateResult = validateField($input.val(), $input.data('type'));
                        if (!validateResult.value) {
                            valid = false;
                        }
                    });
                }
            });
            return valid;
        };

        var navigateToNextPage = function (currentTarget) {
            var $currentPage = $(currentTarget).closest('.lightbox-content.page');

            // Validate current page avant
            if (validatePage($currentPage)) {
                hideFormError();
                var $nextPage = $currentPage.next('.lightbox-content.page');
                $currentPage.attr('data-slide', 'hideLeft');
                $nextPage.attr('data-slide', 'show');
            } else {
                showFormError();
            }
        };

        var navigateToPrevPage = function (currentTarget) {
            var $currentPage = $(currentTarget).closest('.lightbox-content.page');
            var $prevPage = $currentPage.prev('.lightbox-content.page');
            $currentPage.attr('data-slide', 'hideRight');
            $prevPage.attr('data-slide', 'show');
        };

        var sendEmail = function (currentTarget) {
            navigateToNextPage(currentTarget);
            var service_id = 'default_service';
            var template_id = 'technical_form';
            var $pageConfirm = $form.find('.page-confirm');

            // $.ajax({
            //   crossDomain: true,
            //   url: $.leadit_url.live,
            //   type: 'post',
            //   data: $('form#info_form').serialize(),
            //   headers: {
            //     "Access-Control-Allow-Origin": 'http://app.leadit.quebec',
            //     "Access-Control-Allow-Credentials": 'true'
            //   },
            //   dataType: 'json',
            //   success: function (data) {
            //     $pageConfirm.find('.wait').hide(function () {
            //       $pageConfirm.find('.message.success').show();
            //       //on success, reset form and flags
            //       $('form#info_form')[0].reset();
            //       $.form_leadit.senEmail = '';
            //       $.form_leadit.valid = false;
            //       $.form_leadit.target = '';
            //     });
            //   },
            //   error: function (data) {
            //     $pageConfirm.find('.wait').hide(function () {
            //       $pageConfirm.find('.message.error').show();
            //     });
            //   }
            // });
        };
        //Add callback function to global scope
        //$.form_leadit.senEmail = sendEmail;

        var datajs = function (key, value) {
            return document.querySelectorAll('[data-' + key + '=' + value + ']');
        };

        var clickHandler = function (e) {
            e.preventDefault();
            var $currentPage = $(e.currentTarget).closest('.lightbox-content.page');
            if (validatePage($currentPage)) {
                //form is valid, get recapatcha response and store target globally
                // $.form_leadit.valid = true;
                // $.form_leadit.target = e.currentTarget;
                // grecaptcha.execute();
                navigateToNextPage(e.currentTarget);

                var requestType, firstname, lastname, enterprise, phone, email, address, postalcode, applicationType, comments;
                requestType = firstname = lastname = enterprise = phone = email = address = postalcode = applicationType = comments = '--';

                if ($(document).find('input[type=radio]#technical-q-1a').prop('checked')) {
                    requestType = $(document).find('#technical-q-1a').val();
                }

                if ($(document).find('input[type=radio]#technical-q-1b').prop('checked')) {
                    requestType = $(document).find('#technical-q-1b').val();
                }

                if ($(document).find('input[type=text]#technical-firstname') !== '') {
                    firstname = $(document).find('#technical-firstname').val();
                }

                if ($(document).find('input[type=text]#technical-lastname') !== '') {
                    lastname = $(document).find('#technical-lastname').val();
                }

                if ($(document).find('input[type=text]#technical-enterprise') !== '') {
                    enterprise = $(document).find('#technical-enterprise').val();
                }

                if ($(document).find('input[type=text]#technical-phone_num') !== '') {
                    phone = $(document).find('#technical-phone_num').val();
                }

                if ($(document).find('input[type=email]#technical-mail') !== '') {
                    email = $(document).find('#technical-mail').val();
                }

                if ($(document).find('input[type=text]#technical-address') !== '') {
                    address = $(document).find('#technical-address').val();
                }

                if ($(document).find('input[type=text]#technical-postalcode') !== '') {
                    postalcode = $(document).find('#technical-postalcode').val();
                }

                if ($(document).find('input[type=text]#technical-applicationType') !== '') {
                    applicationType = $(document).find('#technical-applicationType').val();
                }

                if ($(document).find('input[type=textarea]#technical-comments') !== '') {
                    comments = $(document).find('#technical-comments').val();
                }

                

                var templateParams = {
                    'technical-requestType': requestType,
                    'technical-firstname': firstname,
                    'technical-lastname': lastname,
                    'technical-enterprise': enterprise,
                    'technical-phone_num': phone,
                    'technical-mail': email,
                    'technical-address': address,
                    'technical-postalcode': postalcode,
                    'technical-applicationType': applicationType,
                    'technical-comments': comments
                };

                //Send email
                emailjs.send('default_service', 'technical_form', templateParams)
                    .then(function (response) {
                        console.log('SUCCESS!', response.status, response.text);
                        $('form#technical_form').find('.page-confirm .wait').hide();
                        $('form#technical_form').find('.page-confirm .message.success').show();

                        //Fermer la modale
                        setTimeout(function () {
                            $('body').removeClass('no-overflow');
                            document.location.hash = '#!';
                        }, 5000);
                    }, function (error) {
                        console.log('FAILED...', error);
                        $('form#technical_form').find('.page-confirm .wait').hide();
                        $('form#technical_form').find('.page-confirm .message.error').show();

                        setTimeout(function () {
                            //Cacher page-confirm et revenir en arrière d'une page
                            var $prevPage = $('form#technical_form').find('.lightbox-content.page.page4');
                            $('form#technical_form').find('.page-confirm').attr('data-slide', 'hideRight');
                            $prevPage.attr('data-slide', 'show');
                        }, 8000);
                    });
            } else {
                showFormError();
                return false;
            }
        };

        $form.on('click', 'button.prevPage', function (e) {
            navigateToPrevPage(e.currentTarget);
        });

        var resetForm = function () {
            $('body').removeClass('no-overflow');
            setTimeout(function () {
                $form.find('.page').attr('data-slide', 'hideRight');
                $form.find('.page1').removeAttr('data-slide');
            }, 300);
        };

        var preventBodyScroll = function () {
            $('body').addClass('no-overflow');
        };

        var bindEvents = function () {
            //$(datajs('type', 'submit')).on('click', clickHandler);
            $form.find('.submit-technical-form').on('click', clickHandler);
            $form.find('button.cancel').on('click', function () {
                $('body').removeClass('no-overflow');
                document.location.hash = '#!';
            });
            $form.find('button.nextPage').on('click', function (e) {
                navigateToNextPage(e.currentTarget);
            });
            $('#close-lightbox').on('click', resetForm);
            $('a[href$="#f"]').on('click', preventBodyScroll);
        };

        var init = function () {
            bindEvents();
        };

        return {
            init: init
        };

    })(jQuery);

    // ---------------------------------------------------------------------------

    //FORM SOUMISSION*************************************************************
    var formCalltoAction_Soumission = (function ($) {
        var $form_m = $('form#soumission_form');

        var showFormError = function () {
            $form_m.find('.form-error')
                .html('Veuillez vérifier vos réponses, certains champs sont vides ou non valides').show();
        };

        var hideFormError = function () {
            $form_m.find('.form-error').html('').hide();
        };

        var isPhone = function (phone) {
            var regex = /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/;
            return regex.test(phone);
        };

        // $('#prospect_fullname').on('change', function () {
        //   $('#prospect_name').val($(this).val());
        // });
        //
        // $('#prospect_mail').on('change', function () {
        //   $('#prospect_email').val($(this).val());
        // });
        //
        // $('#prospect_phone_num').on('change', function () {
        //   var phone = $(this).val();
        //   var phonestripped = phone.replace(' ', '').replace('-', '').replace('(', '').replace(')', '').replace('-', '');
        //
        //   $('#prospect_phone_stripped').val(phonestripped);
        // });

        // $('form#info_form .form-checkbox-list label').on('click', function(){
        //   var prospect_name = $('#prospect_contract_name').val();
        //   prospect_name += " - " + $(this).html();
        //   $('#prospect_contract_name').val(prospect_name);
        // });

        // validate one field given its value and data type [email or phone]
        var validateField = function (v, dt) {
            var r = {
                'value': true,
                'message': ''
            };
            if (v === '') {
                r.value = false;
            }
            if (dt === 'email' && !isEmail(v)) {
                r.value = false;
            } else if (dt === 'phone' && !isPhone(v)) {
                r.value = false;
            }
            return r;
        };

        var validatePage = function ($page) {
            var $inputGroup = $page.find('.form-group');
            var valid = true;
            // $inputGroup.each(function (i, e) {
            //     var $e = $(e);
            //     // Si checkbox ou radiobutton
            //     if ($e.hasClass('form-checkbox-list')) {
            //         if ($e.find('input:radio:checked').length === 0) {
            //             valid = false;
            //         }
            //     } else {
            //         $e.find('input, select').each(function (index, element) {
            //             var $input = $(element);
            //             var validateResult = validateField($input.val(), $input.data('type'));
            //             if (!validateResult.value) {
            //                 valid = false;
            //             }
            //         });
            //     }
            // });
            return valid;
        };

        var navigateToNextPage = function (currentTarget) {
            var $currentPage = $(currentTarget).closest('.lightbox-content.page');

            // Validate current page avant
            if (validatePage($currentPage)) {
                console.log('Test')
                hideFormError();
                var $nextPage = $currentPage.next('.lightbox-content.page');
                $currentPage.attr('data-slide', 'hideLeft');
                $nextPage.attr('data-slide', 'show');
            } else {
                showFormError();
            }
        };

        var navigateToPrevPage = function (currentTarget) {
            var $currentPage = $(currentTarget).closest('.lightbox-content.page');
            var $prevPage = $currentPage.prev('.lightbox-content.page');
            $currentPage.attr('data-slide', 'hideRight');
            $prevPage.attr('data-slide', 'show');
        };

        var sendEmail = function (currentTarget) {
            navigateToNextPage(currentTarget);
            var service_id = 'default_service';
            var template_id = 'XXXXXXX';
            var $pageConfirm = $form_m.find('.page-confirm');

            // $.ajax({
            //   crossDomain: true,
            //   url: $.leadit_url.live,
            //   type: 'post',
            //   data: $('form#info_form').serialize(),
            //   headers: {
            //     "Access-Control-Allow-Origin": 'http://app.leadit.quebec',
            //     "Access-Control-Allow-Credentials": 'true'
            //   },
            //   dataType: 'json',
            //   success: function (data) {
            //     $pageConfirm.find('.wait').hide(function () {
            //       $pageConfirm.find('.message.success').show();
            //       //on success, reset form and flags
            //       $('form#info_form')[0].reset();
            //       $.form_leadit.senEmail = '';
            //       $.form_leadit.valid = false;
            //       $.form_leadit.target = '';
            //     });
            //   },
            //   error: function (data) {
            //     $pageConfirm.find('.wait').hide(function () {
            //       $pageConfirm.find('.message.error').show();
            //     });
            //   }
            // });
        };
        //Add callback function to global scope
        //$.form_leadit.senEmail = sendEmail;

        var datajs = function (key, value) {
            return document.querySelectorAll('[data-' + key + '=' + value + ']');
        };

        var clickHandler = function (e) {
            e.preventDefault();
            var $currentPage = $(e.currentTarget).closest('.lightbox-content.page');
            if (validatePage($currentPage)) {
                //form is valid, get recapatcha response and store target globally
                // $.form_leadit.valid = true;
                // $.form_leadit.target = e.currentTarget;
                // grecaptcha.execute();
                navigateToNextPage(e.currentTarget);

                var q1a, q1b, q1c, q2a, q2b, q2c, name, phone, zipcode, email;
                q1a = q1b = q1c = q2a = q2b = q2c = name = phone = zipcode = email = '--';

                if ($(document).find('input[type=radio]#soumission-q-1a').prop("checked")) {
                    q1a = $(document).find('#soumission-q-1a').val();
                }

                if ($(document).find('input[type=radio]#soumission-q-1b').prop("checked")) {
                    q1b = $(document).find('#soumission-q-1b').val();
                }

                if ($(document).find('input[type=radio]#soumission-q-1c').prop("checked")) {
                    q1c = $(document).find('#soumission-q-1c').val();
                }

                if ($(document).find('input[type=radio]#soumission-q-2a').prop("checked")) {
                    q2a = $(document).find('#soumission-q-2a').val();
                }

                if ($(document).find('input[type=radio]#soumission-q-2b').prop("checked")) {
                    q2b = $(document).find('#soumission-q-2b').val();
                }

                if ($(document).find('input[type=radio]#soumission-q-2c').prop("checked")) {
                    q2c = $(document).find('#soumission-q-2c').val();
                }

                if ($(document).find('input[type=text]#soumission-fullname') !== "") {
                    name = $(document).find('#soumission-fullname').val();
                }

                if ($(document).find('input[type=text]#soumission-phone_num') !== "") {
                    phone = $(document).find('#soumission-phone_num').val();
                }

                if ($(document).find('input[type=text]#soumission-postalcode') !== "") {
                    zipcode = $(document).find('#soumission-postalcode').val();
                }

                if ($(document).find('input[type=text]#soumission-mail') !== "") {
                    email = $(document).find('#soumission-mail').val();
                }

                var templateParams = {
                    'soumission-q-1a': q1a,
                    'soumission-q-1b': q1b,
                    'soumission-q-1c': q1c,
                    'soumission-q-2a': q2a,
                    'soumission-q-2b': q2b,
                    'soumission-q-2c': q2c,
                    'soumission-fullname': name,
                    'soumission-phone_num': phone,
                    'soumission-postalcode': zipcode,
                    'soumission-mail': email,
                };

                //Send email
                emailjs.send('default_service', 'soumission_form', templateParams)
                    .then(function (response) {
                        console.log('SUCCESS!', response.status, response.text);
                        $('form#soumission_form').find('.page-confirm .wait').hide();
                        $('form#soumission_form').find('.page-confirm .message.success').show();

                        //Fermer la modale
                        setTimeout(function () {
                            $('body').removeClass('no-overflow');
                            document.location.hash = '#!';
                        }, 5000);
                    }, function (error) {
                        console.log('FAILED...', error);
                        $('form#soumission_form').find('.page-confirm .wait').hide();
                        $('form#soumission_form').find('.page-confirm .message.error').show();

                        setTimeout(function () {
                            //Cacher page-confirm et revenir en arrière d'une page
                            var $prevPage = $('form#soumission_form').find('.lightbox-content.page.page4');
                            $('form#soumission_form').find('.page-confirm').attr('data-slide', 'hideRight');
                            $prevPage.attr('data-slide', 'show');
                        }, 8000);
                    });
            } else {
                showFormError();
                return false;
            }
        };

        $form_m.on('click', 'button.prevPage', function (e) {
            navigateToPrevPage(e.currentTarget);
        });

        var resetForm = function () {
            $('body').removeClass('no-overflow');
            setTimeout(function () {
                $form_m.find('.page').attr('data-slide', 'hideRight');
                $form_m.find('.page1').removeAttr('data-slide');
            }, 300);
        };

        var preventBodyScroll = function () {
            $('body').addClass('no-overflow');
        };

        var bindEvents = function () {
            //$(datajs('type', 'submit')).on('click', clickHandler);
            $form_m.find('.submit-soumission-form').on('click', clickHandler);
            $form_m.find('button.cancel').on('click', function () {
                $('body').removeClass('no-overflow');
                document.location.hash = '#!';
            });
            $form_m.find('button.nextPage').on('click', function (e) {
                navigateToNextPage(e.currentTarget);
            });
            $('#close-lightbox').on('click', resetForm);
            $('a[href$="#f"]').on('click', preventBodyScroll);
        };

        var init = function () {
            bindEvents();
        };

        return {
            init: init
        };
    })(jQuery);

    // ---------------------------------------------------------------------------

    //FORM CONTACT*************************************************************
  var formCalltoAction_Contact = (function ($) {
    var $form_c = $('form#email_us');

    var showFormError = function () {
      $form_c.find('.form-error')
        .html('Veuillez vérifier vos réponses, certains champs sont vides ou non valides').show();
    };

    var hideFormError = function () {
      $form_c.find('.form-error').html('').hide();
    };

    var isPhone = function (phone) {
      var regex = /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/;
      return regex.test(phone);
    };

    // $('#prospect_fullname').on('change', function () {
    //   $('#prospect_name').val($(this).val());
    // });
    //
    // $('#prospect_mail').on('change', function () {
    //   $('#prospect_email').val($(this).val());
    // });
    //
    // $('#prospect_phone_num').on('change', function () {
    //   var phone = $(this).val();
    //   var phonestripped = phone.replace(' ', '').replace('-', '').replace('(', '').replace(')', '').replace('-', '');
    //
    //   $('#prospect_phone_stripped').val(phonestripped);
    // });

    // $('form#info_form .form-checkbox-list label').on('click', function(){
    //   var prospect_name = $('#prospect_contract_name').val();
    //   prospect_name += " - " + $(this).html();
    //   $('#prospect_contract_name').val(prospect_name);
    // });

    // validate one field given its value and data type [email or phone]
    var validateField = function (v, dt) {
      var r = {
        'value': true,
        'message': ''
      };
      if (v === '') {
        r.value = false;
      }
      if (dt === 'email' && !isEmail(v)) {
        r.value = false;
      } else if (dt === 'phone' && !isPhone(v)) {
        r.value = false;
      }
      return r;
    };

    var validatePage = function ($page) {
      var $inputGroup = $page.find('.form-group');
      var valid = true;
      $inputGroup.each(function (i, e) {
        var $e = $(e);
        // Si checkbox ou radiobutton
        if ($e.hasClass('form-checkbox-list')) {
          if ($e.find('input:radio:checked').length === 0) {
            valid = false;
          }
        } else {
          $e.find('input, select').each(function (index, element) {
            var $input = $(element);
            var validateResult = validateField($input.val(), $input.data('type'));
            if (!validateResult.value) {
              valid = false;
            }
          });
        }
      });
      return valid;
    };

    var navigateToNextPage = function (currentTarget) {
      var $currentPage = $(currentTarget).closest('.lightbox-content.page');

      // Validate current page avant
      if (validatePage($currentPage)) {
        hideFormError();
        var $nextPage = $currentPage.next('.lightbox-content.page');
        $currentPage.attr('data-slide', 'hideLeft');
        $nextPage.attr('data-slide', 'show');
      } else {
        showFormError();
      }
    };

    var navigateToPrevPage = function (currentTarget) {
      var $currentPage = $(currentTarget).closest('.lightbox-content.page');
      var $prevPage = $currentPage.prev('.lightbox-content.page');
      $currentPage.attr('data-slide', 'hideRight');
      $prevPage.attr('data-slide', 'show');
    };

    var sendEmail = function (currentTarget) {
      navigateToNextPage(currentTarget);
      var service_id = 'default_service';
      var template_id = 'technical_form';
      var $pageConfirm = $form_m.find('.page-confirm');

      // $.ajax({
      //   crossDomain: true,
      //   url: $.leadit_url.live,
      //   type: 'post',
      //   data: $('form#info_form').serialize(),
      //   headers: {
      //     "Access-Control-Allow-Origin": 'http://app.leadit.quebec',
      //     "Access-Control-Allow-Credentials": 'true'
      //   },
      //   dataType: 'json',
      //   success: function (data) {
      //     $pageConfirm.find('.wait').hide(function () {
      //       $pageConfirm.find('.message.success').show();
      //       //on success, reset form and flags
      //       $('form#info_form')[0].reset();
      //       $.form_leadit.senEmail = '';
      //       $.form_leadit.valid = false;
      //       $.form_leadit.target = '';
      //     });
      //   },
      //   error: function (data) {
      //     $pageConfirm.find('.wait').hide(function () {
      //       $pageConfirm.find('.message.error').show();
      //     });
      //   }
      // });
    };
    //Add callback function to global scope
    //$.form_leadit.senEmail = sendEmail;

    var datajs = function (key, value) {
      return document.querySelectorAll('[data-' + key + '=' + value + ']');
    };

    var clickHandler = function (e) {
      e.preventDefault();
      var $currentPage = $(e.currentTarget).closest('.lightbox-content.page');
      if (validatePage($currentPage)) {
        //form is valid, get recapatcha response and store target globally
        // $.form_leadit.valid = true;
        // $.form_leadit.target = e.currentTarget;
        // grecaptcha.execute();
        navigateToNextPage(e.currentTarget);

        var firstname, lastname, email, phone, subject, message;
        name = email = phone = subject = message = '--';

        if ($(document).find('input[type=text]#contact-fullname') !== '') {
          name = $(document).find('#contact-fullname').val();
        }

        if ($(document).find('input[type=text]#contact-mail') !== '') {
          email = $(document).find('#contact-mail').val();
        }

        if ($(document).find('input[type=text]#contact-phone_num') !== '') {
          phone = $(document).find('#contact-phone_num').val();
        }

        if ($(document).find('input[type=text]#contact-subject') !== '') {
          subject = $(document).find('#contact-subject').val();
        }

        if ($(document).find('input[type=text]#contact-message') !== '') {
          message = $(document).find('#contact-message').val();
        }

        var templateParams = {
          'contact-fullname': name,
          'contact-mail': email,
          'contact-phone_num': phone,
          'contact-subject': subject,
          'contact-message': message,
        };

        //Send email
        emailjs.send('default_service', 'email_us', templateParams)
          .then(function (response) {
            console.log('SUCCESS!', response.status, response.text);
            $('form#email_us').find('.page-confirm .wait').hide();
            $('form#email_us').find('.page-confirm .message.success').show();

            //Fermer la modale
            setTimeout(function () {
              $('body').removeClass('no-overflow');
              document.location.hash = '#!';
            }, 5000);
          }, function (error) {
            console.log('FAILED...', error);
            $('form#email_us').find('.page-confirm .wait').hide();
            $('form#email_us').find('.page-confirm .message.error').show();

            setTimeout(function () {
              //Cacher page-confirm et revenir en arrière d'une page
              var $prevPage = $('form#email_us').find('.lightbox-content.page.page4');
              $('form#email_us').find('.page-confirm').attr('data-slide', 'hideRight');
              $prevPage.attr('data-slide', 'show');
            }, 8000);
          });
      } else {
        showFormError();
        return false;
      }
    };

    $form_c.on('click', 'button.prevPage', function (e) {
      navigateToPrevPage(e.currentTarget);
    });

    var resetForm = function () {
      $('body').removeClass('no-overflow');
      setTimeout(function () {
        $form_c.find('.page').attr('data-slide', 'hideRight');
        $form_c.find('.page1').removeAttr('data-slide');
      }, 300);
    };

    var preventBodyScroll = function () {
      $('body').addClass('no-overflow');
    };

    var bindEvents = function () {
      //$(datajs('type', 'submit')).on('click', clickHandler);
      $form_c.find('.submit-contact-form').on('click', clickHandler);
      $form_c.find('button.cancel').on('click', function () {
        $('body').removeClass('no-overflow');
        document.location.hash = '#!';
      });
      $form_c.find('button.nextPage').on('click', function (e) {
        navigateToNextPage(e.currentTarget);
      });
      $('#close-lightbox').on('click', resetForm);
      $('a[href$="#f"]').on('click', preventBodyScroll);
    };

    var init = function () {
      bindEvents();
    };

    return {
      init: init
    };
  })(jQuery);

  // ---------------------------------------------------------------------------

    formCalltoAction.init();
    formCalltoAction_Soumission.init();
    formCalltoAction_Contact.init();
    toggleMenu();
    fixHeader();

})(jQuery, emailjs, window, document);

// function send_leadit_prospect() {
//   //if form is valid and we have a target, create leadit opportunity from callback
//   if($.form_leadit.valid && $.form_leadit.target != null){
//     $.form_leadit.senEmail($.form_leadit.target);
//   }
// }

$(document).ready(function () {
    if ($('.fullSlider').length > 0) {
        console.log('Slick init');
        $('.fullSlider').slick({
            dots: true,
            infinite: true,
            speed: 1800,
            slidesToShow: 1,
            adaptiveHeight: true,
            autoplay: true,
            fade: true,
            arrows: true,
            autoplaySpeed: 4500
        });
    }

    //Init phone mask
    $('.phone_with_mask').mask('(000) 000-0000');

    //Init zipcode mask
    $('.zipcode_with_mask').mask('A0A 0A0');
});

var isEmail = function (email) {
    var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regex.test(email);
};